import {SET_SELECTED_TRIP} from "../actions/actionTypes";

const initialState = {
    trip: []
};

const trip = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_TRIP: {
            return {
                ...state,
                trip: action.data
            };
        }
        default:
            return state;
    }
};

export default trip;