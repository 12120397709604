export const SET_AUTH_TOKENS = "SET_AUTH_TOKENS";
export const SET_PRIVATE_TOKEN = "SET_PRIVATE_TOKEN";
export const SET_AUTH_TYPE = "SET_AUTH_TYPE";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
export const SET_PHONE = "SET_PHONE";

export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const FETCH_TABLE_DATA = "FETCH_TABLE_DATA";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

export const FETCH_DELETE_MODEL = "FETCH_DELETE_MODEL";

export const SET_SELECTED_TRIP = "SET_SELECTED_TRIP";

export const SET_AUTH_STATUS = "SET_AUTH_STATUS";
export const SET_CAPTCHA = "SET_CAPTCHA";
export const SET_IS_ROBOT = "SET_IS_ROBOT";

export const QUERY_CHANGED = "QUERY_CHANGED";
export const SET_QUERY_POINTS = "SET_QUERY_POINTS"

export const SET_POPULAR_POINTS = "SET_POPULAR_POINTS";

export const SET_SEO = "SET_SEO";

