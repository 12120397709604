import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Link, NavLink, useLocation, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getI18n, withTranslation} from 'react-i18next';
import Select from "react-select";
import liqpay from "../../assets/images/liqpay.webp";
import visa from "../../assets/images/visa.webp";
import mastercard from "../../assets/images/mastercard.webp";
import aos from "../../assets/images/aos.webp";
import ios from "../../assets/images/ios.webp";
import pay24 from "../../assets/images/24pay.webp";
import gpay from "../../assets/images/gpay.webp";
import {setAuthStatus, setPhone, setPrivateToken} from "../../redux/actions";
import axios from "axios";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";

const isProd = () => { try { require("../../prod.js"); return true; } catch (err) { return false; } };
const REACT_APP_BACKEND_URL = isProd() ? "/backend" : "";

const DashboardLayout = (props) => {
    const language = getI18n().language;
    const url = `${REACT_APP_BACKEND_URL}/api/docs/?fileType=pda&lang=` + language;

    const api = useSelector(state => state.api);
    const dispatch = useDispatch();
    const {t} = props;
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isLanguageVisible, setIsLanguageVisible] = useState(false);
    const [langOptions, setLangOptions] = useState([
        {value: 'ua', label: "Українська мова", url: "автобуси"},
        {value: 'ru', label: "Русский язык", url: "автобусы"},
        {value: 'en', label: "English language", url: "buses"}
    ]);
    const [selectedLang, setSelectedLang] = useState([{value: 'ua', label: "Укр"}]);
    //black-on-white; white-on-transparent
    const [headerBackgroundClass, setHeaderBackgroundClass] = useState("white-on-transparent")
    const [appLinks, setAppLinks] = useState([{key: "aos", value: "/"}, {key: "ios", value: "/"}]);
    const [linksReady, setLinksReady] = useState(false);

    let history = useHistory();
    let location = useLocation();
    // const transparentHeaderLocations = ["/", "/buses", "/автобусы", "/автобуси"];
    const transparentHeaderLocations = ["/login", "/contact", "/about", "/faq", "/terms-and-conditions", "/privacy-policy", "/personal-data-processing", "/preorder", "/checkout", "/payment", "/profile"];

    //parse language from URL
    const lang = langOptions.find(el=>el.url===location.pathname.split('/')[1])?.value;

    useLayoutEffect(() => {
        if (lang !== undefined && lang !== null && lang !== language) {
            getI18n().changeLanguage(lang);
            localStorage.setItem("language", lang);
        }
    }, [])

    useEffect(() => {
        if (lang !== undefined && lang !== null && lang !== language) {
            setSelectedLang({
                value: lang,
                label: lang === "ru" ? "Рус" : (lang === "en" ? "En" : "Укр")
            })
        } else {
            if (localStorage.hasOwnProperty('language')) {
                setSelectedLang({
                    value: localStorage.getItem('language'),
                    label: localStorage.getItem('language') === "ru" ? "Рус" : (localStorage.getItem('language') === "en" ? "En" : "Укр")
                })
            }
        }


        if (localStorage.hasOwnProperty('app_links')) {
            const ls = JSON.parse(localStorage.getItem("app_links"));
            if (ls.expiresAt < (Date.now())) {
                getSettings();
            } else {
                if (ls.data && ls.data.length > 0) {
                    setAppLinks(ls.data);
                    setLinksReady(true);
                } else {
                    getSettings();
                }
            }
        } else {
            getSettings();
        }
    }, [])


    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [location.pathname])

    useEffect(() => {
        setIsLoggedIn(api.isLoggedIn)

        let _location = location.pathname.split("/").length > 1 ? "/" + location.pathname.split("/")[1] : location.pathname;
        if (transparentHeaderLocations.includes(_location)) {
            setHeaderBackgroundClass("black-on-white")
        } else {
            setHeaderBackgroundClass("white-on-transparent")
        }
    }, [location])

    const getSettings = async () => {
        await axios({
            method: 'get',
            url: api.address + "/settings/get-app-links",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'Bearer ' + api.authToken
            }
        }).then((response) => {
            if (response.data !== '' && response.data.constructor === Object) {
                let date = Date.now() + 86400000;
                const data = {
                    data: response.data.data,
                    expiresAt: date
                };
                localStorage.setItem("app_links", JSON.stringify(data));
                setAppLinks(response.data.data);
                // const aos = response.data.data.find((el) => el.key === "aos");
                // let x = aos.value;
                // console.log(x)
                setLinksReady(true);
            }
        }).catch((error) => {
            setLinksReady(true)
            // console.log(error)
        })
    }

    const toggleMenu = (isFromDiv) => {
        if (isFromDiv) {
            setIsMenuOpen(false)
        } else {
            setIsMenuOpen(!isMenuOpen)
        }
    }

    const toggleLanguage = () => {
        setIsLanguageVisible(!isLanguageVisible)
    }


    const checkLoggedIn = () => {
        if (api.authToken) {
            return true;
        } else {
            return false;
        }
    }

    const checkAuthType = (types) => {
        return (types === api.authType) ? true : (types.includes(api.authType));
    }

    const onLanguageHandle = (event, onlyVal = false) => {
        let path = location.pathname.split('/');
        if(path.length > 3) {
            path[1] = langOptions.find(el=>el.value === event.value).url;
            // url.searchParams.set('lang', onlyVal ? event : event.value);
            history.replace({pathname: path.join("/")});
        }
        localStorage.setItem('language', onlyVal ? event : event.value);
        window.location.reload(false);
    }

    const logout = async () => {
        await axios({
            method: 'get',
            url: api.address + "/default/logout",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'Bearer ' + api.authToken
            }
        }).then(function (response) {
            if (response.data !== '' && response.data.constructor === Object) {
                setIsLoggedIn(false)
                localStorage.removeItem("privateToken");
                localStorage.removeItem("phone");
                localStorage.removeItem("email");

                dispatch(setAuthStatus(false));
                dispatch(setPrivateToken(''));
                dispatch(setPhone(''));

                setIsMobileMenuVisible(false)
            }
        }).catch(function (error) {
            // Alert.alert(error.message)
        });
    }

    const getLink = (type) => {
        let res = "/";
        switch (type) {
            case "aos":
                const aos = appLinks.find(el => el.key === "aos");
                res = aos.value;
                break;
            case "ios":
                const ios = appLinks.find(el => el.key === "ios");
                res = ios.value;
                break;
        }

        return res;
    }

    return (
        <div className={"wrap " + (isMobileMenuVisible ? "disable-scroll" : "")}>
            <div className={'main-container'}
                 onClick={() => toggleMenu(true)}>
                <div className={"header-holder " + headerBackgroundClass}>
                    <div className={'header ' + headerBackgroundClass}>
                        <NavLink onClick={() => {
                            setIsMobileMenuVisible(false)
                        }} to={"/"} className={"header-logo"}>
                            <div className={"logo-icon"}>
                                <i className="fas fa-bus-alt"></i>
                            </div>
                            {
                                isMobileMenuVisible ?
                                    <div className={"logo-title black-on-white"}>
                                        MonoBus
                                    </div> :
                                    <div className={"logo-title " + headerBackgroundClass}>
                                        MonoBus
                                    </div>
                            }
                        </NavLink>
                        <div className={"header-items " + headerBackgroundClass}>
                            {/*<NavLink className={"header-item"} to={"/"}>*/}
                            {/*    <i className="fas fa-headset"></i> Служба підтримки*/}
                            {/*</NavLink>*/}
                            {
                                isLoggedIn ?
                                    location.pathname.includes("profile") ?
                                        <div
                                            className={"header-item interactive-link no-border " + headerBackgroundClass}
                                            onClick={() => {
                                                logout()
                                            }}>
                                            <i className="far fa-user"></i> {t("authorisation.logout")}
                                        </div> :
                                        <NavLink
                                            className={"header-item interactive-link no-border " + headerBackgroundClass}
                                            to={"/profile"}>
                                            <i className="far fa-user"></i> {t("authorisation.profile")}
                                        </NavLink>
                                    :
                                    <NavLink
                                        className={"header-item interactive-link no-border " + headerBackgroundClass}
                                        to={"/login"}>
                                        <i className="far fa-user"></i> {t("authorisation.login")}
                                    </NavLink>
                            }
                            <div className={"header-item language language-breadcrumbs"}>
                                <Select
                                    className={"lang-dropdown " + headerBackgroundClass}
                                    classNamePrefix={"lang"}
                                    isSearchable={false}
                                    onChange={(e) => onLanguageHandle(e)}
                                    components={{
                                        DropdownIndicator: () => <i className="fas fa-chevron-down"></i>,
                                        IndicatorSeparator: () => null
                                    }}
                                    value={selectedLang}
                                    options={langOptions}
                                />
                            </div>
                        </div>
                        <div style={{color: isMobileMenuVisible ? "#47AB43" : "inherit"}}
                             className={"burger " + headerBackgroundClass + (isMobileMenuVisible ? " is-active" : "")}
                             onClick={() => {
                                 setIsMobileMenuVisible(!isMobileMenuVisible)
                             }}>
                            {/*{isMobileMenuVisible ? <i className="far fa-times"></i> :*/}
                            {/*<i className="fas fa-bars"></i>}*/}
                            <div className={"burger-inner"}>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"mobile-header"} style={{display: isMobileMenuVisible ? "flex" : "none"}}>
                    <div>
                        <div className={""}>
                            <Accordion className={"mobile-lang"}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{color: "#47AB43"}}/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography style={{fontSize: "18px"}}><i
                                        className="far fa-globe"></i> {langOptions.filter((o) => {
                                        return o.value === selectedLang.value
                                    }).map((o) => {
                                        return o.label
                                    })}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={"lang-list"}>
                                    <Typography onClick={() => {
                                        onLanguageHandle('ua', true)
                                    }}>
                                        <span className={selectedLang.value === "ua" ? "active-lang" : ""}>Українська мова (UA)</span>
                                    </Typography>
                                    <Typography onClick={() => {
                                        onLanguageHandle("ru", true)
                                    }}>
                                        <span className={selectedLang.value === "ru" ? "active-lang" : ""}>Русский язык (RU)</span>
                                    </Typography>
                                    <Typography onClick={() => {
                                        onLanguageHandle("en", true)
                                    }}>
                                        <span className={selectedLang.value === "en" ? "active-lang" : ""}>English language (EN)</span>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className={"mobile-menu-item"}>
                            <NavLink onClick={() => {
                                setIsMobileMenuVisible(false)
                            }} activeClassName={location.pathname === "/" ? 'menu-active' : ''} to={"/"} className={""}>
                                <i className="fas fa-search"></i> {t("navbar.search_tickets")}
                            </NavLink>
                            <NavLink onClick={() => {
                                setIsMobileMenuVisible(false)
                            }} activeClassName='menu-active' to={isLoggedIn ? "/profile" : "/login"} className={""}>
                                <i className="far fa-user"></i> {isLoggedIn ? t("authorisation.profile") : t("authorisation.login")}
                            </NavLink>
                        </div>
                        <div className={"mobile-menu-item"}>
                            <NavLink onClick={() => {
                                setIsMobileMenuVisible(false)
                            }} activeClassName='menu-active' to={"/about"} className={""}>
                                {t("navbar.about")}
                            </NavLink>
                            <NavLink onClick={() => {
                                setIsMobileMenuVisible(false)
                            }} activeClassName='menu-active' to={"/faq"} className={""}>
                                {t("navbar.faq")}
                            </NavLink>
                            <NavLink onClick={() => {
                                setIsMobileMenuVisible(false)
                            }} activeClassName='menu-active' to={"/contact"} className={""}>
                                {t("navbar.contact")}
                            </NavLink>
                        </div>
                        <div className={"mobile-menu-item"}>
                            <NavLink onClick={() => {
                                setIsMobileMenuVisible(false)
                            }} activeClassName='menu-active' to={"/terms-and-conditions"} className={""}>
                                {t("navbar.t&c")}
                            </NavLink>
                            {language == "en" ? (
                                <NavLink to={"/privacy-policy"}>{t("navbar.policy")}</NavLink>
                            ) : null}
                            <a href={url} target={"_blank"}>{t("navbar.personal")}</a>
                        </div>
                        {
                            isLoggedIn ?
                                <div className={"mobile-menu-item"}>
                                    <NavLink onClick={() => {
                                        logout()
                                    }} to={"/"} className={""}>
                                        <i className="far fa-sign-out"></i> {t("authorisation.logout")}
                                    </NavLink>
                                </div> : null
                        }
                    </div>
                </div>
                <div className={"body-content"}>
                    {props.children}
                </div>
                <div className={"footer"}>
                    <div className={"container"}>
                        <div className={"icons"}>
                            <img src={liqpay} alt={"liqpay"} height={"64px"} width={"64px"}/>
                            <img src={pay24} alt={"pay24"} height={"64px"} width={"64px"}/>
                            <img src={gpay} alt={"gpay"} height={"64px"} width={"95px"}/>
                            <img src={visa} alt={"visa"} height={"64px"} width={"64px"}/>
                            <img src={mastercard} alt={"mastercard"} height={"64px"} width={"80px"}/>
                        </div>
                        <div className={"links"}>
                            <div>
                                <Link to={"/about"}>{t("navbar.about")}</Link>
                                <Link to={"/faq"}>{t("navbar.questions_and_answers")}</Link>
                                <Link to={"/contact"}>{t("navbar.contact")}</Link>
                            </div>
                            <div>
                                <Link to={"/terms-and-conditions"}>{t("navbar.t&c")}</Link>
                                {language == "en" ? (
                                    <Link to={"/privacy-policy"}>{t("navbar.policy")}</Link>
                                ) : null}
                                <a href={url} target={"_blank"}>{t("navbar.personal")}</a>
                            </div>
                            <div>
                                {
                                    linksReady ?
                                        <>
                                            <a href={linksReady === false ? "/" : getLink("ios")}><img src={ios} alt={"ios"} height={"40px"} width={"120px"}/></a>
                                            <a href={linksReady === false ? "/" : getLink("aos")}><img src={aos} alt={"aos"} height={"40px"} width={"133px"}/></a>
                                        </> : null
                                }
                            </div>
                        </div>
                        <div className={"copyright"}>
                            <span>&copy; 2021, MonoBus. ALL RIGHTS RESERVED</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(DashboardLayout);
