import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

function PhoneRoute({component: Component, api, ...rest}) {
    //ToDo: Add "No access" page

    //get phone and token from local storage to compare with redux storage values
    const phone = localStorage.getItem("phone");
    const privateToken = localStorage.getItem("privateToken");

    return (
        <Route
            {...rest}
            render={(props) => {
                let resultPage = '';
                if (api.phone !== "" && api.privateToken !== "") {
                    if (phone === api.phone && privateToken === api.privateToken) {
                        resultPage = <Component {...props} />;
                    } else {
                        resultPage = <Redirect to={{pathname: '/', state: {referer: props.location}}}/>
                    }
                } else {
                    resultPage = <Redirect to={{pathname: '/login', state: {referer: props.location}}}/>
                }

                return resultPage;
            }}
        />
    )
}

const mapStateToProps = (state) => ({
    api: state.api
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PhoneRoute)
