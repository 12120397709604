import React from "react";
import {withTranslation} from "react-i18next";

const PageNotFound = (props) => {
    const t = props.t
    return (
        <div className={"pnf-container"}>
            <div className={"pnf"}>
                <div className={"pnf-icon"}>
                </div>
                <div>
                    <h1>404</h1>
                    <h2>{t('404.header')}</h2>
                    <p>{t('404.desc1')}</p>
                    <p>{t('404.desc2')}</p>
                    <a style={{borderRadius: "25px"}} href={"/"} className="btn my-btn green-btn wide-btn">{t('404.home')}</a>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(PageNotFound);