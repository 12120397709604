import {call, takeLatest} from "redux-saga/effects";
import {
    deleteModel
} from "../../api";
import {
    FETCH_DELETE_MODEL
} from "../actions/actionTypes";

function* fetchProducts(action) {
    try {
        const {
            authToken,
            modelName,
            modelToDelete,
            callbackAfterDeletion,
            refetchPageData
        } = action.payload;

        yield call(deleteModel(authToken, modelName, modelToDelete));
        callbackAfterDeletion()
        refetchPageData();
    } catch (e) {
        console.log(e)
    }
}

export default function* fetchProductsWrapper() {
    yield takeLatest(FETCH_DELETE_MODEL, fetchProducts);
}