import React, {Component, Suspense, lazy} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import store from './redux/store';
import {Provider} from 'react-redux';
import {DataLoader} from "react-yii2-essentials";

import "./styles/styles";
import Auth from "./Auth";
import DashboardLayout from "./components/layouts/DashboardLayout";
import {withTranslation} from 'react-i18next';
import PageNotFound from "./components/base/PageNotFound";
import PhoneRoute from "./components/base/PhoneRoute";

//routes
// import Login from "./containers/Login";
// import Home from "./containers/Home";
// import Contact from "./containers/Contact";
// import About from "./containers/About";
// import Faq from "./containers/Faq";
// import Terms from "./containers/Terms";
// import Policy from "./containers/Policy";
// import PersonalData from "./containers/PersonalData";
// import Preorder from "./containers/order/Preorder";
// import Checkout from "./containers/order/Checkout";
// import Payment from "./containers/order/Payment";
// import Trips from "./containers/profile/Index";

const Login = lazy(() => import('./containers/Login'));
const Home = lazy(() => import('./containers/Home'));
const Contact = lazy(() => import('./containers/Contact'));
const About = lazy(() => import('./containers/About'));
const Faq = lazy(() => import('./containers/Faq'));
const Terms = lazy(() => import('./containers/Terms'));
const Policy = lazy(() => import('./containers/Policy'));
const PersonalData = lazy(() => import('./containers/PersonalData'));
const Preorder = lazy(() => import('./containers/order/Preorder'));
const Checkout = lazy(() => import('./containers/order/Checkout'));
const Payment = lazy(() => import('./containers/order/Payment'));
const Trips = lazy(() => import('./containers/profile/Index'));


// import Logout from "./containers/Logout";
// import History from "./containers/profile/History";
// import Info from "./containers/profile/Info";

class App extends Component {

    render() {
        const {t} = this.props
        return (
            <Provider store={store}>
                <Router>
                    <Auth>
                        <DashboardLayout>
                            <Suspense fallback={<div className={"main-data-loader"}><DataLoader/></div>}>
                                <Switch>
                                    <Route exact path='/' component={Home}/>

                                    <Route exact path="/login" component={Login}/>

                                    <Route exact path='/contact' component={Contact}/>
                                    <Route exact path='/about' component={About}/>
                                    <Route exact path='/faq' component={Faq}/>
                                    <Route exact path='/terms-and-conditions' component={Terms}/>
                                    <Route exact path='/privacy-policy' component={Policy}/>
                                    <Route exact path='/personal-data-processing' component={PersonalData}/>

                                    <Route exact path='/preorder/:id' component={Preorder}/>
                                    <Route exact path='/checkout/:id' component={Checkout}/>

                                    <PhoneRoute exact path='/payment/:id' component={Payment}/>

                                    <PhoneRoute exact path='/profile' component={Trips}/>

                                    {/*additional HOME routes should be here because any route can match those patterns*/}
                                    <Route path='/:lang/:from/:to/:dates/:seats' component={Home}/>
                                    <Route path='/:lang/:from/:to/:dates' component={Home}/>
                                    <Route path='/:lang/:from/:to' component={Home}/>
                                    <Route path='/:lang' component={Home}/>

                                    <Route path="*">
                                        <PageNotFound/>
                                    </Route>
                                </Switch>
                            </Suspense>
                        </DashboardLayout>
                    </Auth>
                </Router>
            </Provider>
        );
    }
}

export default withTranslation()(App);