import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import languageRU from './assets/locate/ru/translate.json'
import languageUA from './assets/locate/ua/translate.json'
import languageEn from './assets/locate/en/translate.json'
i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            ru: languageRU,
            ua: languageUA,
            en: languageEn
        },
        /* default language when load the website in browser */
        lng: "ua",
        /* When react i18next not finding any language to as default in browser */
        fallbackLng: "ua",
        /* debugger For Development environment */
        debug: true,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
        }
    })

export default i18n;