import axios from "axios";
import {DOMAIN_HOST} from "../consts";
import stringify from "qs-stringify";

export const getModels = (token, model, currentPage) => async () =>
    await axios({
        method: 'post',
        url: `${DOMAIN_HOST}/${model}/index?page=${currentPage}`,
        data: stringify({}),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            Authorization: 'Bearer ' + token
        }
    })

export const deleteModel = (token, model, id) => async () =>
    await axios({
        method: 'post',
        url: `${DOMAIN_HOST}/${model}/delete?id=${id}`,
        data: stringify({}),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            Authorization: 'Bearer ' + token
        }
    })