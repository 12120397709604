import {
    SET_AUTH_TOKENS,
    SET_AUTH_TYPE,
    SET_BREADCRUMBS,
    SET_PHONE,
    SET_TABLE_DATA,
    SET_PAGE_NUMBER,

    FETCH_TABLE_DATA,
    FETCH_DELETE_MODEL,
    SET_SELECTED_TRIP,

    SET_PRIVATE_TOKEN,
    SET_IS_ROBOT,
    SET_CAPTCHA,
    SET_AUTH_STATUS,
    QUERY_CHANGED,
    SET_POPULAR_POINTS,
    SET_QUERY_POINTS,
    SET_SEO
} from "./actionTypes";

export const dispatchPOSTDeleteModel = (authToken, modelName, modelToDelete, callbackAfterDeletion, refetchPageData) => ({
    type: FETCH_DELETE_MODEL,
    payload: {
        authToken,
        modelName,
        modelToDelete,
        callbackAfterDeletion,
        refetchPageData
    }
})

export const dispatchSetPageNumber = (page) => ({
    type: SET_PAGE_NUMBER,
    payload: {
        page
    }
})

export const dispatchSetTableData = (data, error) => ({
    type: SET_TABLE_DATA,
    payload: {
        data,
        error
    }
})

export const dispatchFetchData = (authToken, model, currentPage, id) => ({
    type: FETCH_TABLE_DATA,
    payload: {
        authToken,
        model,
        currentPage,
        id
    }
})

export const setAuthTokens = (data) => ({
    type: SET_AUTH_TOKENS,
    data: data
});

export const setAuthType = (data) => ({
    type: SET_AUTH_TYPE,
    data: data
});

export const setPhone = (data) => ({
    type: SET_PHONE,
    data: data
});

export const setBreadcrumbs = (data) => ({
    type: SET_BREADCRUMBS,
    data: data
});

export const setSelectedTrip = (data) => ({
    type: SET_SELECTED_TRIP,
    data: data
});
export const setPrivateToken = (data) => ({
    type: SET_PRIVATE_TOKEN,
    data: data
});
export const setCaptcha = (data) => ({
    type: SET_CAPTCHA,
    data: data
});

export const setIsRobot = (data) => ({
    type: SET_IS_ROBOT,
    data: data
});

export const setAuthStatus = (data) => ({
    type: SET_AUTH_STATUS,
    data: data
});

export const queryChanged = (data) => ({
    type: QUERY_CHANGED,
    data: data
})

export const setQueryPoints = (data) => ({
    type: SET_QUERY_POINTS,
    data: data
})

export const setPopularPoints = (data) => ({
    type: SET_POPULAR_POINTS,
    data: data
})

export const setSeo = (data) => ({
    type: SET_SEO,
    data: data
})


