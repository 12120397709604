import {call, put, takeLatest} from "redux-saga/effects";
import {
    getModels
} from "../../api";
import {
    SET_TABLE_DATA,
    FETCH_TABLE_DATA
} from "../actions/actionTypes";
import {
    dispatchSetPageNumber,
    dispatchSetTableData
} from "../actions";

function* fetchProducts(action) {
    try {
        const {
            authToken,
            model,
            currentPage,
            id
        } = action.payload;
        if (id)
            yield put(dispatchSetPageNumber(id));

        const data = yield call(getModels(authToken, model, id ? id : currentPage));
        //console.log('fetch', authToken, model, currentPage, id, data)
        yield put(dispatchSetTableData(data.data.data, null));
    } catch (e) {
        yield put(dispatchSetTableData([], e.message));
    }
}

export default function* fetchProductsWrapper() {
    yield takeLatest(FETCH_TABLE_DATA, fetchProducts);
}