import { spawn } from 'redux-saga/effects'

import fetchModel from "./fetchModel";
import deleteModel from "./deleteModel";

function* rootSaga() {
    yield spawn(fetchModel);
    yield spawn(deleteModel);
}

export default rootSaga;