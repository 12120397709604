import React, {Component} from 'react';
import {connect} from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {DataLoader} from "react-yii2-essentials";
import axios from "axios";
import stringify from "qs-stringify";
import {setAuthTokens, setPhone, setAuthType, setPrivateToken, setAuthStatus} from "./redux/actions";
import i18n from "i18next";
import publicIp from "public-ip";
import {v4 as uuidv4} from 'uuid';

class Auth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVerified: false,
            isDataLoaded: false
        };
    }

    componentDidMount = () => {
        const device_id = uuidv4();
        this.verifyIdentity(device_id);
    }

    verifyIdentity = async (device_id) => {
        if (localStorage.hasOwnProperty('language')) {
            await i18n.changeLanguage(localStorage.getItem('language'));
        } else {
            localStorage.setItem('language', 'ua');
            await i18n.changeLanguage('ua');
        }

        if (localStorage.hasOwnProperty('tokens')) {
            const pubTokens = localStorage.getItem("tokens");

            this.props.setAuthTokens(pubTokens);

            if (localStorage.hasOwnProperty('privateToken')) {
                const privToken = localStorage.getItem("privateToken");
                if (privToken !== "" && privToken !== null && privToken !== undefined) {
                    let this_el = this;
                    await axios({
                        method: 'post',
                        url: this.props.api.address + "/default/verify-token",
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                            'Authorization': 'Bearer ' + pubTokens
                        },
                        data: stringify({
                            data: privToken
                        }),
                    }).then(function (response) {
                        if (response.data !== '' && response.data.constructor === Object) {
                            let event = response.data;
                            this_el.props.setAuthStatus(event.data)
                            this_el.props.setPrivateToken(privToken);

                            if (localStorage.hasOwnProperty("phone")) {
                                const phone = localStorage.getItem('phone');
                                if (phone !== '' && phone !== null && phone !== undefined) {
                                    this_el.props.setPhone(phone)
                                }
                            }
                            this_el.setState({isVerified: true});
                            this_el.setState({isDataLoaded: true});
                        }
                    }).catch(function (error) {
                        this_el.setState({isDataLoaded: true});
                        console.log(error.message)
                    });
                } else {
                    this.setState({isVerified: true});
                    this.setState({isDataLoaded: true});
                    // console.log("private token not found. looks like you're not registered or signed in")
                }
            } else {
                this.setState({isVerified: true});
                this.setState({isDataLoaded: true});
                // console.log("private token not found. looks like you're not registered or signed in")
            }
        } else {
            let this_el = this;
            await axios({
                method: 'post',
                url: this.props.api.address + "/default/verify-guest-credentials",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                data: stringify({
                    device_id: device_id
                }),
            }).then(function (response) {
                if (response.data !== '' && response.data.constructor === Object) {
                    let event = response.data;
                    localStorage.setItem('tokens', event.data.token);
                    this_el.props.setAuthTokens(event.data.token);
                    this_el.setState({isVerified: true});
                    this_el.setState({isDataLoaded: true});
                }
            }).catch(function (error) {
                this_el.setState({isDataLoaded: true});
                console.log(error.message)
            });
        }
    }

    render() {
        return (
            (this.state.isVerified) ?
                (this.state.isDataLoaded) ? this.props.children : <DataLoader/>
                : <DataLoader/>

        );
    }
}

const mapStateToProps = state => ({
    api: state.api
});

const mapDispatchToProps = dispatch => ({
    setAuthTokens: breadcrumb => dispatch(setAuthTokens(breadcrumb)),
    setPhone: breadcrumb => dispatch(setPhone(breadcrumb)),
    setAuthType: data => dispatch(setAuthType(data)),
    setPrivateToken: data => dispatch(setPrivateToken(data)),
    setAuthStatus: data => dispatch(setAuthStatus(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);