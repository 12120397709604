import api from './api';
import breadcrumbs from './breadcrumbs';
import table from './tableReducer';
import trip from './trip';

export default ({
    api,
    breadcrumbs,
    table,
    trip
});