import {
    SET_TABLE_DATA,
    SET_PAGE_NUMBER
} from "../actions/actionTypes";

const initialState = {
    data: [],
    currentPageNumber: 1,
    totalPagesQuantity: 1,
    error: null
};

const table = (state = initialState, action) => {
    switch (action.type) {
        case SET_TABLE_DATA:
            return {
                ...state,
                data: action.payload.data.data,
                totalPagesQuantity: action.payload.data.total_pages,
                error: action.payload.error
            };
        case SET_PAGE_NUMBER:
            return {
                ...state,
                currentPageNumber: action.payload.page
            }
        default:
            return {
                ...state
            }
    }
};

export default table;