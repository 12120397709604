import {
    SET_AUTH_TOKENS,
    SET_AUTH_TYPE,
    SET_PRIVATE_TOKEN,
    SET_PHONE,
    SET_CAPTCHA,
    SET_IS_ROBOT,
    SET_AUTH_STATUS,
    QUERY_CHANGED, SET_POPULAR_POINTS, SET_QUERY_POINTS, SET_SEO
} from "../actions/actionTypes";

const IS_DEV = false;
let startAddress = 'https://';
let cleanAddress = '';

const isProd = () => { try { require("../../prod.js"); return true; } catch (err) { return false; } };
const REACT_APP_BACKEND_URL = isProd() ? "/backend" : "";

const initialState = {
    authToken: '',
    privateToken: '',
    phone: '',
    authType: '',
    address:  `${REACT_APP_BACKEND_URL}/api`,
    cleanAddress: `${REACT_APP_BACKEND_URL}`,
    screen: '1',
    isLoggedIn: false,
    isRobot: false,
    captcha: '',
    queryChanged: false,
    queryPoints: {
        from: {
            id: null,
            name: null
        },
        to: {
            id: null,
            name: null
        },
        dates: null,
        seats: null,
        lang: null
    },
    popularPoints: [],
    seo: null
};

const api = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_TOKENS:
            localStorage.setItem("tokens", action.data);
            return {
                ...state,
                authToken: action.data
            };
        case SET_AUTH_TYPE:
            return {
                ...state,
                authType: action.data
            };
        case SET_PHONE:
            localStorage.setItem("phone", action.data);
            return {
                ...state,
                phone: action.data
            };
        case SET_PRIVATE_TOKEN:
            localStorage.setItem("privateToken", action.data);
            return {
                ...state,
                privateToken: action.data
            };
        case SET_CAPTCHA:
            return {
                ...state,
                captcha: action.data
            }
        case SET_IS_ROBOT:
            return {
                ...state,
                isRobot: action.data
            }
        case SET_AUTH_STATUS:
            return {
                ...state,
                isLoggedIn: action.data
            }
        case QUERY_CHANGED:
            return {
                ...state,
                queryChanged: action.data
            }
        case SET_QUERY_POINTS:
            return {
                ...state,
                queryPoints: action.data
            }
        case SET_POPULAR_POINTS:
            return {
                ...state,
                popularPoints: action.data
            }
        case SET_SEO:
            return {
                ...state,
                seo: action.data
            }
        default:
            return state;
    }
};

export default api;
